import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: {
      title: ' KantooorFlex | Huren van een kantoorRuimte in Nederland',
    },
  },
  {
    path: '/Partijen',
    name: 'Partijen',
    component: () =>
      import(/* webpackChunkName: "Overons" */ '../views/Partijen.vue'),
    meta: {
      title: ' Flexplek huren verhuren | Flexibele kantoorruimte | Kantoorflex',
    },
  },

  {
    path: '/LosseNummers',
    name: 'LosseNummers',
    component: () =>
      import(
        /* webpackChunkName: "Postzegelss-diensten" */ '../views/LosseNummers'
      ),
    meta: {
      title: ' Integratie partners | Onze diensten ',
    },
  },

  {
    path: '/Inkoop',
    name: 'Inkoop',
    component: () =>
      import(
        /* webpackChunkName: "Integratie-cases" */ '../views/Inkoop.vue'
      ),
    meta: {
      title: ' Integratie-cases| Postzegelss',
    },
  },

  {
    path: '/Info',
    name: 'Info',
    component: () =>
      import(
        /* webpackChunkName: "Postzegelss-diensten" */ '../views/Info.vue'
      ),
    meta: {
      title: '  Postzegelss | Expertise ',
    },
  },

//Footer\


{
  path: '/Algemenevoorwaarden',
  name: 'Algemenevoorwaarden',
  component: () =>
    import(
      /* webpackChunkName: "Postzegelss-diensten" */ '../views/Algemenevoorwaarden.vue'
    ),
  meta: {
    title: '  Postzegels | Expertise ',
  },
},

{
  path: '/Bestelprocedure',
  name: 'Bestelprocedure',
  component: () =>
    import(
      /* webpackChunkName: "Postzegelss-diensten" */ '../views/Bestelprocedure.vue'
    ),
  meta: {
    title: '  Postzegels | Expertise ',
  },
},

{
  path: '/Handelaren',
  name: 'Handelaren',
  component: () =>
    import(
      /* webpackChunkName: "Postzegelss-diensten" */ '../views/Handelaren.vue'
    ),
  meta: {
    title: '  Postzegels | Expertise ',
  },
},
{
  path: '/OverOns',
  name: 'OverOns',
  component: () =>
    import(
      /* webpackChunkName: "Postzegelss-diensten" */ '../views/OverOns.vue'
    ),
  meta: {
    title: '  Postzegels | Expertise ',
  },
},

{
  path: '/BevestigBestelling/:id',
  name: 'BevestigBestelling',
  component: () =>
    import(
      /* webpackChunkName: "Postzegelss-diensten" */ '../views/BevestigBestelling.vue'
    ),
  meta: {
    title: '  Postzegels | Bevestig Bestelling ',
  },
},

{
  path: '/Winkelwagen',
  name: 'Winkelwagen',
  component: () =>
    import(
      /* webpackChunkName: "Postzegelss-diensten" */ '../views/Winkelwagen.vue'
    ),
  meta: {
    title: '  Winkelwagen | Postzegels ',
  },
},


{
  path: '/Confirmation',
  name: 'Confirmation',
  component: () =>
    import(
      /* webpackChunkName: "Postzegelss-diensten" */ '../views/Confirmation.vue'
    ),
  meta: {
    title: '  Winkelwagen | Postzegels ',
  },
},


{
  path: '/Routebeschrijving',
  name: 'Routebeschrijving',
  component: () =>
    import(
      /* webpackChunkName: "Postzegelss-diensten" */ '../views/Routebeschrijving.vue'
    ),
  meta: {
    title: '  Routebeschrijving | Postzegels ',
  },
},


{
  path: '/Details/:id',
  name: 'Details',
  component: () =>
    import(
      /* webpackChunkName: "Postzegelss-diensten" */ '../views/Details.vue'
    ),
  meta: {
    title: '  Postzegels | Details ',
  },
},




  {
    path: '*',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "NotFound" */ '@/views/404.vue'),
    meta: { title: ' Postzegels| Oeps pagina niet gevonden' },
  },


  
];



const router = new VueRouter({
  mode: 'history',
  //Dit voor weer naar begin
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});



export default router;