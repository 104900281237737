class LoggingService {
    constructor(baseUrl) {
        this.baseUrl = baseUrl; // Gebruik dit als basis-URL
    }

    async logEvent(eventData) {
        try {
            const response = await fetch(this.baseUrl, { // Gebruik de basis-URL
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error logging event:', error);
            // Optioneel: Voeg hier extra foutafhandeling toe
        }
    }
}

// Bij het aanmaken van de instance, geef je de basis-URL mee
export default new LoggingService('https://server.postzegels.com/api/rest/v2/activities/log');
