<template>
<div>
  <button v-show="isVisible" @click="scrollToTop" class="back-to-top">
      ↑ Terug naar boven
    </button>
  <v-footer color="#375a9e" padless>
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="link in links"
        :key="link.text"
        :to="link.to"
        color="white"
        text
        rounded
        class="my-2"
      >
        {{ link.text }}
      </v-btn>
   
    </v-row>
  </v-footer>
<v-container>
  <v-row class="footer">
    <v-col>
   
<img  src="@/assets/home/nvph_logo_small.png"  width="80" height="80" style="margin:5px"/>
 
  <img  src="@/assets/home/pts_logo_1.png" width="80" height="80" />
    </v-col>

  <v-col>
<p>Alle prijzen zijn inclusief BTW, exclusief eventuele verzendkosten.
Postzegels.com is de website van:</p>
<img src="@/assets/footer-center.gif" />
<p>
  Postzegels: inkoop en verkoop van collecties en partijen. <br>
Copyright © {{ currentYear }}. Koop postzegels bij postzegels.com 
Ook een nieuwe <a href="https://integratiepartners.nl">website laten maken?</a>
   </p>
    </v-col>
      <v-col>
<p>Veilig kopen</p>
<img src="@/assets/footer-right.gif" />
<img src="@/assets/footer-facebook.gif" />
    </v-col>

  </v-row>
</v-container>
</div>
</template>

<script>
export default {
  data: () => ({
    isVisible: false,
    links: [
      { text: "Bestelprocedure |", to: "Bestelprocedure" },
      { text: "Routebeschrijving |", to: "/Routebeschrijving" },
      { text: "Inkopen |", to: "/Inkoop" },
      { text: "Handelaren |", to: "/Handelaren" },
      { text: "Algemene voorwaarden |", to: "Algemenevoorwaarden" },
        { text: "Over ons |", to: "OverOns" },
          { text: "Info |", to: "Info" },
    ],
  }),
    computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isVisible = window.scrollY > 200; // Toon de knop na 200 pixels scrollen
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Zorgt voor een vloeiende scrollbeweging
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll); // Belangrijk om event listeners te verwijderen
  }
};
</script>
<style scoped>
.amelie a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}
.menu-item {
  color: black;
  margin-left: 5px;
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.footer{
  font-size: 11px;
color: #375A9E;
}
</style>
<style scoped>
.back-to-top {
  position: fixed;
  bottom: 20px; /* 20 pixels vanaf de onderkant van de pagina */
  right: 20px; /* 20 pixels vanaf de rechterkant van de pagina */
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.4s;
}

.back-to-top:hover {
  opacity: 1; /* Maak de knop volledig zichtbaar bij hover */
}
</style>
