import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/app"; 
import "firebase/auth";
import store from '@/components/store';
import LoggingService from './components/Services/LoggingService'; // Zorg dat het pad correct is
import VueMeta from "vue-meta";
import VueSweetalert2 from "vue-sweetalert2";
import AOS from "aos";
import "sweetalert2/dist/sweetalert2.min.css";
import "aos/dist/aos.css";
import Vue2Editor from "vue2-editor";
import VueLazyload from "vue-lazyload";
import { VBHover } from "bootstrap-vue";

Vue.use(Vue2Editor);
Vue.use(VBHover);
Vue.use(VueLazyload);
Vue.use(VueSweetalert2);

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCuYr_XgCRj7VPl--FDrWGoLReGjXDmWd0",
  authDomain: "kantoorfles.firebaseapp.com",
  projectId: "kantoorfles",
  storageBucket: "kantoorfles.appspot.com",
  messagingSenderId: "841440400593",
  appId: "1:841440400593:web:66dd02c54ae36917be61f0",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  created() {
    document.addEventListener('click', e => {
      let target = e.target;
      while (target && target.tagName !== 'A') {
        target = target.parentNode;
      }
      if (target && target.tagName === 'A') {
        LoggingService.logEvent({
          website:"NL",
          eventType: 'Website pagina bezocht',
          href: target.href,
          timestamp: new Date().toISOString(),
        });
      }
    });
  },
  render: h => h(App),
}).$mount('#app');

Vue.use(VueMeta, { refreshOnceOnNavigation: true });
AOS.init({ disable: "mobile" });

