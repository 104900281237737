import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectedKavels: [],
    totalAmount: 0, 
  },
  mutations: {
    addToCart(state, kavel) {
      state.selectedKavels.push(kavel);
      state.totalAmount += parseFloat(kavel.promotionPrice ?? kavel.price); // Converteer de prijs naar een numerieke waarde
    },
  },
});
